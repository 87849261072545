export const quranMetaData = [
  { id: 0 },
  { id: 1, arabic: "الفاتحة", translation: "The Opening", transliteration: "Al Faatiha", verses: 7, revelation: 1, icon: "" },
  { id: 2, arabic: "البقرة", translation: "The Cow", transliteration: "Al Baqara", verses: 286, revelation: 2, icon: "" },
  { id: 3, arabic: "آل عمران", translation: "The Family of Imraan", transliteration: "Aal i Imraan", verses: 200, revelation: 2, icon: "" },
  { id: 4, arabic: "النساء", translation: "The Women", transliteration: "An Nisaa", verses: 176, revelation: 2, icon: "" },
  { id: 5, arabic: "المائدة", translation: "The Table", transliteration: "Al Maaida", verses: 120, revelation: 2, icon: "" },
  { id: 6, arabic: "الأنعام", translation: "The Cattle", transliteration: "Al An'aam", verses: 165, revelation: 1, icon: "" },
  { id: 7, arabic: "الأعراف", translation: "The Heights", transliteration: "Al A'raaf", verses: 206, revelation: 1, icon: "" },
  { id: 8, arabic: "الأنفال", translation: "The Spoils of War", transliteration: "Al Anfaal", verses: 75, revelation: 2, icon: "" },
  { id: 9, arabic: "التوبة", translation: "The Repentance", transliteration: "At Tawba", verses: 129, revelation: 2, icon: "" },
  { id: 10, arabic: "يونس", translation: "Jonas", transliteration: "Yunus", verses: 109, revelation: 1, icon: "" },
  { id: 11, arabic: "هود", translation: "Hud", transliteration: "Hud", verses: 123, revelation: 1, icon: "" },
  { id: 12, arabic: "يوسف", translation: "Joseph", transliteration: "Yusuf", verses: 111, revelation: 1, icon: "" },
  { id: 13, arabic: "الرعد", translation: "The Thunder", transliteration: "Ar Ra'd", verses: 43, revelation: 2, icon: "" },
  { id: 14, arabic: "ابراهيم", translation: "Abraham", transliteration: "Ibrahim", verses: 52, revelation: 1, icon: "" },
  { id: 15, arabic: "الحجر", translation: "The Rock", transliteration: "Al Hijr", verses: 99, revelation: 1, icon: "" },
  { id: 16, arabic: "النحل", translation: "The Bee", transliteration: "An Nahl", verses: 128, revelation: 1, icon: "" },
  { id: 17, arabic: "الإسراء", translation: "The Night Journey", transliteration: "Al Israa", verses: 111, revelation: 1, icon: "" },
  { id: 18, arabic: "الكهف", translation: "The Cave", transliteration: "Al Kahf", verses: 110, revelation: 1, icon: "" },
  { id: 19, arabic: "مريم", translation: "Mary", transliteration: "Maryam", verses: 98, revelation: 1, icon: "" },
  { id: 20, arabic: "طه", translation: "Taa-Haa", transliteration: "Taa Haa", verses: 135, revelation: 1, icon: "" },
  { id: 21, arabic: "الأنبياء", translation: "The Prophets", transliteration: "Al Anbiyaa", verses: 112, revelation: 1, icon: "" },
  { id: 22, arabic: "الحج", translation: "The Pilgrimage", transliteration: "Al Hajj", verses: 78, revelation: 2, icon: "" },
  { id: 23, arabic: "المؤمنون", translation: "The Believers", transliteration: "Al Muminoon", verses: 118, revelation: 1, icon: "" },
  { id: 24, arabic: "النور", translation: "The Light", transliteration: "An Noor", verses: 64, revelation: 2, icon: "" },
  { id: 25, arabic: "الفرقان", translation: "The Criterion", transliteration: "Al Furqaan", verses: 77, revelation: 1, icon: "" },
  { id: 26, arabic: "الشعراء", translation: "The Poets", transliteration: "Ash Shu'araa", verses: 227, revelation: 1, icon: "" },
  { id: 27, arabic: "النمل", translation: "The Ant", transliteration: "An Naml", verses: 93, revelation: 1, icon: "" },
  { id: 28, arabic: "القصص", translation: "The Stories", transliteration: "Al Qasas", verses: 88, revelation: 1, icon: "" },
  { id: 29, arabic: "العنكبوت", translation: "The Spider", transliteration: "Al Ankaboot", verses: 69, revelation: 1, icon: "" },
  { id: 30, arabic: "الروم", translation: "The Romans", transliteration: "Ar Room", verses: 60, revelation: 1, icon: "" },
  { id: 31, arabic: "لقمان", translation: "Luqman", transliteration: "Luqman", verses: 34, revelation: 1, icon: "" },
  { id: 32, arabic: "السجدة", translation: "The Prostration", transliteration: "As Sajda", verses: 30, revelation: 1, icon: "" },
  { id: 33, arabic: "الأحزاب", translation: "The Clans", transliteration: "Al Ahzaab", verses: 73, revelation: 2, icon: "" },
  { id: 34, arabic: "سبإ", translation: "Sheba", transliteration: "Saba", verses: 54, revelation: 1, icon: "" },
  { id: 35, arabic: "فاطر", translation: "The Originator", transliteration: "Faatir", verses: 45, revelation: 1, icon: "" },
  { id: 36, arabic: "يس", translation: "Yaseen", transliteration: "Yaseen", verses: 83, revelation: 1, icon: "" },
  { id: 37, arabic: "الصافات", translation: "Those drawn up in Ranks", transliteration: "As Saaffaat", verses: 182, revelation: 1, icon: "" },
  { id: 38, arabic: "ص", translation: "The letter Saad", transliteration: "Saad", verses: 88, revelation: 1, icon: "" },
  { id: 39, arabic: "الزمر", translation: "The Groups", transliteration: "Az Zumar", verses: 75, revelation: 1, icon: "" },
  { id: 40, arabic: "غافر", translation: "The Forgiver", transliteration: "Al Ghaafir", verses: 85, revelation: 1, icon: "" },
  { id: 41, arabic: "فصلت", translation: "Explained in detail", transliteration: "Fussilat", verses: 54, revelation: 1, icon: "" },
  { id: 42, arabic: "الشورى", translation: "Consultation", transliteration: "Ash Shura", verses: 53, revelation: 1, icon: "" },
  { id: 43, arabic: "الزخرف", translation: "Ornaments of gold", transliteration: "Az Zukhruf", verses: 89, revelation: 1, icon: "" },
  { id: 44, arabic: "الدخان", translation: "The Smoke", transliteration: "Ad Dukhaan", verses: 59, revelation: 1, icon: "" },
  { id: 45, arabic: "الجاثية", translation: "Crouching", transliteration: "Al Jaathiya", verses: 37, revelation: 1, icon: "" },
  { id: 46, arabic: "الأحقاف", translation: "The Dunes", transliteration: "Al Ahqaf", verses: 35, revelation: 1, icon: "" },
  { id: 47, arabic: "محمد", translation: "Muhammad", transliteration: "Muhammad", verses: 38, revelation: 2, icon: "" },
  { id: 48, arabic: "الفتح", translation: "The Victory", transliteration: "Al Fath", verses: 29, revelation: 2, icon: "" },
  { id: 49, arabic: "الحجرات", translation: "The Inner Apartments", transliteration: "Al Hujuraat", verses: 18, revelation: 2, icon: "" },
  { id: 50, arabic: "ق", translation: "The letter Qaaf", transliteration: "Qaaf", verses: 45, revelation: 1, icon: "" },
  { id: 51, arabic: "الذاريات", translation: "The Winnowing Winds", transliteration: "Adh Dhaariyat", verses: 60, revelation: 1, icon: "" },
  { id: 52, arabic: "الطور", translation: "The Mount", transliteration: "At Tur", verses: 49, revelation: 1, icon: "" },
  { id: 53, arabic: "النجم", translation: "The Star", transliteration: "An Najm", verses: 62, revelation: 1, icon: "" },
  { id: 54, arabic: "القمر", translation: "The Moon", transliteration: "Al Qamar", verses: 55, revelation: 1, icon: "" },
  { id: 55, arabic: "الرحمن", translation: "The Beneficent", transliteration: "Ar Rahmaan", verses: 78, revelation: 2, icon: "" },
  { id: 56, arabic: "الواقعة", translation: "The Inevitable", transliteration: "Al Waaqia", verses: 96, revelation: 1, icon: "" },
  { id: 57, arabic: "الحديد", translation: "The Iron", transliteration: "Al Hadid", verses: 29, revelation: 2, icon: "" },
  { id: 58, arabic: "المجادلة", translation: "The Pleading Woman", transliteration: "Al Mujaadila", verses: 22, revelation: 2, icon: "" },
  { id: 59, arabic: "الحشر", translation: "The Exile", transliteration: "Al Hashr", verses: 24, revelation: 2, icon: "" },
  { id: 60, arabic: "الممتحنة", translation: "She Who Is Examined", transliteration: "Al Mumtahana", verses: 13, revelation: 2, icon: "" },
  { id: 61, arabic: "الصف", translation: "The Ranks", transliteration: "As Saff", verses: 14, revelation: 2, icon: "" },
  { id: 62, arabic: "الجمعة", translation: "Friday", transliteration: "Al Jumu'a", verses: 11, revelation: 2, icon: "" },
  { id: 63, arabic: "المنافقون", translation: "The Hypocrites", transliteration: "Al Munaafiqoon", verses: 11, revelation: 2, icon: "" },
  { id: 64, arabic: "التغابن", translation: "Mutual Disillusion", transliteration: "At Taghaabun", verses: 18, revelation: 2, icon: "" },
  { id: 65, arabic: "الطلاق", translation: "Divorce", transliteration: "At Talaaq", verses: 12, revelation: 2, icon: "" },
  { id: 66, arabic: "التحريم", translation: "The Prohibition", transliteration: "At Tahrim", verses: 12, revelation: 2, icon: "" },
  { id: 67, arabic: "الملك", translation: "The Sovereignty", transliteration: "Al Mulk", verses: 30, revelation: 1, icon: "" },
  { id: 68, arabic: "القلم", translation: "The Pen", transliteration: "Al Qalam", verses: 52, revelation: 1, icon: "" },
  { id: 69, arabic: "الحاقة", translation: "The Reality", transliteration: "Al Haaqqa", verses: 52, revelation: 1, icon: "" },
  { id: 70, arabic: "المعارج", translation: "The Ascending Stairways", transliteration: "Al Ma'aarij", verses: 44, revelation: 1, icon: "" },
  { id: 71, arabic: "نوح", translation: "Noah", transliteration: "Nooh", verses: 28, revelation: 1, icon: "" },
  { id: 72, arabic: "الجن", translation: "The Jinn", transliteration: "Al Jinn", verses: 28, revelation: 1, icon: "" },
  { id: 73, arabic: "المزمل", translation: "The Enshrouded One", transliteration: "Al Muzzammil", verses: 20, revelation: 1, icon: "" },
  { id: 74, arabic: "المدثر", translation: "The Cloaked One", transliteration: "Al Muddaththir", verses: 56, revelation: 1, icon: "" },
  { id: 75, arabic: "القيامة", translation: "The Resurrection", transliteration: "Al Qiyaama", verses: 40, revelation: 1, icon: "" },
  { id: 76, arabic: "الانسان", translation: "Man", transliteration: "Al Insaan", verses: 31, revelation: 2, icon: "" },
  { id: 77, arabic: "المرسلات", translation: "The Emissaries", transliteration: "Al Mursalaat", verses: 50, revelation: 1, icon: "" },
  { id: 78, arabic: "النبإ", translation: "The Announcement", transliteration: "An Naba", verses: 40, revelation: 1, icon: "" },
  { id: 79, arabic: "النازعات", translation: "Those who drag forth", transliteration: "An Naazi'aat", verses: 46, revelation: 1, icon: "" },
  { id: 80, arabic: "عبس", translation: "He frowned", transliteration: "Abasa", verses: 42, revelation: 1, icon: "" },
  { id: 81, arabic: "التكوير", translation: "The Overthrowing", transliteration: "At Takwir", verses: 29, revelation: 1, icon: "" },
  { id: 82, arabic: "الإنفطار", translation: "The Cleaving", transliteration: "Al Infitaar", verses: 19, revelation: 1, icon: "" },
  { id: 83, arabic: "المطففين", translation: "Defrauding", transliteration: "Al Mutaffifin", verses: 36, revelation: 1, icon: "" },
  { id: 84, arabic: "الإنشقاق", translation: "The Splitting Open", transliteration: "Al Inshiqaaq", verses: 25, revelation: 1, icon: "" },
  { id: 85, arabic: "البروج", translation: "The Constellations", transliteration: "Al Burooj", verses: 22, revelation: 1, icon: "" },
  { id: 86, arabic: "الطارق", translation: "The Morning Star", transliteration: "At Taariq", verses: 17, revelation: 1, icon: "" },
  { id: 87, arabic: "الأعلى", translation: "The Most High", transliteration: "Al A'laa", verses: 19, revelation: 1, icon: "" },
  { id: 88, arabic: "الغاشية", translation: "The Overwhelming", transliteration: "Al Ghaashiya", verses: 26, revelation: 1, icon: "" },
  { id: 89, arabic: "الفجر", translation: "The Dawn", transliteration: "Al Fajr", verses: 30, revelation: 1, icon: "" },
  { id: 90, arabic: "البلد", translation: "The City", transliteration: "Al Balad", verses: 20, revelation: 1, icon: "" },
  { id: 91, arabic: "الشمس", translation: "The Sun", transliteration: "Ash Shams", verses: 15, revelation: 1, icon: "" },
  { id: 92, arabic: "الليل", translation: "The Night", transliteration: "Al Lail", verses: 21, revelation: 1, icon: "" },
  { id: 93, arabic: "الضحى", translation: "The Morning Hours", transliteration: "Ad Dhuhaa", verses: 11, revelation: 1, icon: "" },
  { id: 94, arabic: "الشرح", translation: "The Consolation", transliteration: "Ash Sharh", verses: 8, revelation: 1, icon: "" },
  { id: 95, arabic: "التين", translation: "The Fig", transliteration: "At Tin", verses: 8, revelation: 1, icon: "" },
  { id: 96, arabic: "العلق", translation: "The Clot", transliteration: "Al Alaq", verses: 19, revelation: 1, icon: "" },
  { id: 97, arabic: "القدر", translation: "The Power, Fate", transliteration: "Al Qadr", verses: 5, revelation: 1, icon: "" },
  { id: 98, arabic: "البينة", translation: "The Evidence", transliteration: "Al Bayyina", verses: 8, revelation: 2, icon: "" },
  { id: 99, arabic: "الزلزلة", translation: "The Earthquake", transliteration: "Az Zalzala", verses: 8, revelation: 2, icon: "" },
  { id: 100, arabic: "العاديات", translation: "The Chargers", transliteration: "Al Aadiyaat", verses: 11, revelation: 1, icon: "" },
  { id: 101, arabic: "القارعة", translation: "The Calamity", transliteration: "Al Qaari'a", verses: 11, revelation: 1, icon: "" },
  { id: 102, arabic: "التكاثر", translation: "Competition", transliteration: "At Takaathur", verses: 8, revelation: 1, icon: "" },
  { id: 103, arabic: "العصر", translation: "The Declining Day", transliteration: "Al Asr", verses: 3, revelation: 1, icon: "" },
  { id: 104, arabic: "الهمزة", translation: "The Traducer", transliteration: "Al Humaza", verses: 9, revelation: 1, icon: "" },
  { id: 105, arabic: "الفيل", translation: "The Elephant", transliteration: "Al Fil", verses: 5, revelation: 1, icon: "" },
  { id: 106, arabic: "قريش", translation: "Quraysh", transliteration: "Quraish", verses: 4, revelation: 1, icon: "" },
  { id: 107, arabic: "الماعون", translation: "Almsgiving", transliteration: "Al Maa'un", verses: 7, revelation: 1, icon: "" },
  { id: 108, arabic: "الكوثر", translation: "Abundance", transliteration: "Al Kawthar", verses: 3, revelation: 1, icon: "" },
  { id: 109, arabic: "الكافرون", translation: "The Disbelievers", transliteration: "Al Kaafiroon", verses: 6, revelation: 1, icon: "" },
  { id: 110, arabic: "النصر", translation: "Divine Support", transliteration: "An Nasr", verses: 3, revelation: 2, icon: "" },
  { id: 111, arabic: "المسد", translation: "The Palm Fibre", transliteration: "Al Masad", verses: 5, revelation: 1, icon: "" },
  { id: 112, arabic: "الإخلاص", translation: "Sincerity", transliteration: "Al Ikhlaas", verses: 4, revelation: 1, icon: "" },
  { id: 113, arabic: "الفلق", translation: "The Dawn", transliteration: "Al Falaq", verses: 5, revelation: 1, icon: "" },
  { id: 114, arabic: "الناس", translation: "Mankind", transliteration: "An Naas", verses: 6, revelation: 1, icon: "" },
];
